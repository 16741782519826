import React from "react"

import Layout from '../components/layout';
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import SEO from "../components/seo"

import notFoundSrc from "../images/not-found.svg"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Not Found" description="We could not find what you are looking for" />
    <Navbar />
    <div className="flex flex-1 items-center justify-center">
    	<div className="w-1/2">
     		<img src={notFoundSrc} alt="Not Found" />
     		<div className="flex justify-center">
	     		<h4 className="text-2xl text-center leading-10 font-extrabold text-gray-900 sm:leading-none sm:text-6xl lg:text-3xl xl:text-4xl">
		            We can't find that page you are looking for.
		         </h4>
		    </div>
     	</div>
    </div>
    <Footer />
  </Layout>
)

export default NotFoundPage
